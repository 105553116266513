import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { Seo } from './Seo'
import { CRITICAL_PLACEMENT_LIMIT } from '@constants/cms'
import { PlacementsSwitch } from '@components/Cms/PlacementsSwitch'

import { setPreFooter } from '@features/cms/action'
import { cmsApi } from '@features/cms/query'
import { setHomeContext } from '@features/cms/slice'
import { isFetchingSelector, orderItemsSelector, parsedOrderItemsSelector } from '@features/order/selector'

import { sendHomeEvent } from '@foundation/analytics/tealium/lib'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import {
  ProductForMonetate,
  monetateMapProductsInCart,
  monetateTrackCart,
  monetateTrackProducts,
} from '@foundation/monetate/lib'

import { AppState } from '@redux/store'

import { IPage } from '@typesApp/cms'
import { isCMCollection } from '@typesApp/cmsPlacement/Placement'

import { notNull } from '@utils/common'
import { mergePlacements } from '@utils/placements'

// FIXME: temp imports for svg testing
import { IconButton, Snackbar } from '@mui/material'
import { StyledAlert, StyledAlertHeader } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const Home: React.FC<{ homePageInitial: IPage }> = ({ homePageInitial }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { ...homeAnalyticsData } = useAnalyticsData('home')
  const [hasSentMonetateHomeEvent, setHasSentMonetateHomeEvent] = useState<boolean>(false)
  const isFetchingCart = useSelector(isFetchingSelector)
  const parsedOrderItems = useSelector(parsedOrderItemsSelector)
  const orderItems = useSelector(orderItemsSelector)
  const homePage = useSelector((s: AppState) => s.cms.homeContext)
  const homePlacementsLoaded = homePage?.contentPlacements.every(elem => elem.loaded)

  const { previewDate, filterRulesLocaleOverride } = router.query as {
    previewDate: string
    filterRulesLocaleOverride: string
  }
  const [getPageQuery, { data }] = cmsApi.endpoints.getPage.useLazyQuery()

  const homePageData = useMemo(() => {
    if (homePlacementsLoaded) {
      return homePage
    }
    const fullPlacement = mergePlacements(homePageInitial?.contentPlacements, data?.contentPlacements)
    const fullHeaderData = Object.assign({}, homePageInitial)
    if (homePageInitial && fullPlacement) {
      fullHeaderData.contentPlacements = fullPlacement
      return fullHeaderData
    }
    return homePageInitial
  }, [homePageInitial, data, homePlacementsLoaded, homePage])

  const contentPlacements = homePageData?.contentPlacements || []
  const criticalPlacements = contentPlacements?.slice(0, CRITICAL_PLACEMENT_LIMIT) || []
  const pagePlacements = contentPlacements?.slice(CRITICAL_PLACEMENT_LIMIT) || []
  const preFooter = contentPlacements.find(placement => placement.name === 'footer_before_footer')

  const isCartReady = !isFetchingCart && parsedOrderItems && !Object.values(parsedOrderItems).includes(null)

  useEffect(() => {
    const stateHomePlsLoaded = homePage?.contentPlacements.every(elem => elem.loaded)
    if (!stateHomePlsLoaded && homePlacementsLoaded) {
      dispatch(setHomeContext(homePageData))
    }
  }, [homePageData, dispatch, homePage, homePlacementsLoaded])

  useEffect(() => {
    const params = previewDate || filterRulesLocaleOverride ? { previewDate, filterRulesLocaleOverride } : undefined
    const isHomePageDataFull = homePage?.contentPlacements.every(p => p.loaded)
    if (isHomePageDataFull) return
    const includesParams = homePageInitial?.contentPlacements?.map(el => (!el.loaded ? el.name : null)).filter(notNull)
    if (!includesParams || includesParams.length === 0) return
    getPageQuery({ ...params, includePlacements: includesParams })
  }, [homePageInitial, getPageQuery, homePage])

  useEffect(() => {
    dispatch(setPreFooter(preFooter!))
  }, [preFooter])

  useEffect(() => {
    if (hasSentMonetateHomeEvent) {
      homeAnalyticsData.userToken = getUserToken()
      sendHomeEvent(homeAnalyticsData)
    }
  }, [hasSentMonetateHomeEvent])

  /**
   * Monetate analytics on inital load
   */
  useEffect(() => {
    if (!hasSentMonetateHomeEvent && isCartReady) {
      const productIds: string[] = []
      const productsInCart: ProductForMonetate[] = monetateMapProductsInCart(orderItems)
      const placements = [...criticalPlacements, ...pagePlacements]

      placements.map(placement => {
        const collection = placement.items.filter(item => item !== null).find(isCMCollection)

        if (collection) {
          collection.teasableItems?.map(item => {
            if (item.type === 'CMExternalProduct' && item.productData?.['relationship.item.id']) {
              productIds.push(item.productData['relationship.item.id'])
            }
          })
        }
      })
      monetateTrackProducts({ pageType: 'home', productIds })
      monetateTrackCart({ pageType: 'home', productsInCart })
      setHasSentMonetateHomeEvent(true)
    }
  }, [isCartReady])

  return (
    <>
      <Seo />
      <PlacementsSwitch placements={criticalPlacements} />
      <PlacementsSwitch placements={pagePlacements} preLoadedPlacementCount={criticalPlacements.length} />

      {/* TODO: add icon library */}
      {/* FIXME: keeping for svg icon testing */}
      {/* <Snackbar open>
        <StyledAlert onClose={() => undefined} severity={'info'} icon={<SVGIcon library="media" name="pause" />}>
          <StyledAlertHeader>some test icons here</StyledAlertHeader>

          <IconButton>
            <SVGIcon library="global" name="call-alt" />
          </IconButton>
        </StyledAlert>
      </Snackbar> */}
    </>
  )
}

export default Home
