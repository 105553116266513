import React from 'react'
import Head from 'next/head'
import config from '@configs/index'
import { countryUtil } from '@utils/countryUtil'
import { CommerceEnvironment, LANGUAGE_MASTER } from '@constants/common'
import { replaceSEODomain } from '@foundation/seo/SEO'
import { useSelector } from 'react-redux'
import { hostnameSelector } from '@redux/selectors/site'

export const Seo: React.FC = () => {
  const hostname = useSelector(hostnameSelector)
  const langMap = CommerceEnvironment.localeLangMap

  if (!langMap) return null

  const defaultLangCode = config.defaultLocale.replace('-', '_')
  const xDefaultCurrentLangCode = countryUtil('', defaultLangCode).currentLangCode
  const xDefaultDomain = replaceSEODomain(xDefaultCurrentLangCode, hostname ?? '')
  const xDefaultLangCode = xDefaultCurrentLangCode
  const xDefaultHrefValue = `${xDefaultDomain}/${xDefaultLangCode}`

  return (
    <Head>
      {langMap
        .filter(locale => locale.countryCode !== LANGUAGE_MASTER.countryCode)
        .map(el => {
          const currentCountryConf = countryUtil('', el.langCode)
          const hrefValue = `${replaceSEODomain(currentCountryConf.currentLangCode, hostname ?? '')}/${
            currentCountryConf.currentLangCode
          }`
          return (
            <link
              key={currentCountryConf.currentLangCode}
              rel="alternate"
              hrefLang={currentCountryConf.currentLangCode}
              href={`https://${hrefValue}`}
            />
          )
        })}
      {!!xDefaultHrefValue && <link rel="alternate" hrefLang="x-default" href={`https://${xDefaultHrefValue}`} />}
    </Head>
  )
}
